import React, { useState, useCallback, useEffect } from 'react';
import { collection, addDoc, serverTimestamp, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';

function Comments() {
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  // 댓글 목록 불러오기
  const fetchComments = useCallback(async () => {
    try {
      const q = query(collection(db, 'comments'), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const fetchedComments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));
      setComments(fetchedComments);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setError('댓글을 불러오는 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 컴포넌트 마운트 시 댓글 목록 불러오기
  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!text.trim()) return;

    setIsSubmitting(true);
    setError(null);
    setSuccess(false);

    try {
      await addDoc(collection(db, 'comments'), {
        name: name.trim() || '익명',
        text: text.trim(),
        timestamp: serverTimestamp(),
        status: 'pending',
        adminReply: '',
        updatedAt: null
      });
      
      setName('');
      setText('');
      setSuccess(true);
      
      // 새 댓글 추가 후 목록 새로고침
      await fetchComments();
      
      const timer = setTimeout(() => setSuccess(false), 3000);
      return () => clearTimeout(timer);
    } catch (error) {
      console.error('Error adding comment:', error);
      setError('댓글 저장 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // 댓글 아이템 컴포넌트
  const CommentItem = ({ comment }) => {
    const getStatusBadge = () => {
      switch (comment.status) {
        case 'completed':
          return (
            <span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-md text-xs font-medium bg-emerald-50 text-emerald-700 border border-emerald-200">
              <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
              반영완료
            </span>
          );
        case 'pending':
          return (
            <span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-md text-xs font-medium bg-amber-50 text-amber-700 border border-amber-200">
              <svg className="w-3 h-3 animate-spin" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              검토중
            </span>
          );
        case 'rejected':
          return (
            <span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-md text-xs font-medium bg-red-50 text-red-700 border border-red-200">
              <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
              반영불가
            </span>
          );
        default:
          return null;
      }
    };

    return (
      <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
        <div className="flex justify-between items-start">
          <span className="font-medium text-gray-900">{comment.name || '익명'}</span>
          <span className="text-sm text-gray-500">
            {comment.timestamp?.toLocaleString()}
          </span>
        </div>
        <p className="mt-2 text-gray-700">{comment.text}</p>
        
        {(comment.status === 'completed' || comment.adminReply) && (
          <div className="mt-3 ml-4 pl-4 border-l-2 border-gray-200">
            <div className="flex items-center gap-3">
              {getStatusBadge()}
              {comment.adminReply && (
                <p className="text-sm text-gray-700">
                  <span className="font-medium text-indigo-600">관리자 답변:</span>
                  <span className="ml-1">{comment.adminReply}</span>
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="mb-4">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">댓글</h2>
        <label className="block text-sm font-medium text-gray-700">
          서비스 개선 피드백 또는 학교 추가 요청을 남겨주세요.
        </label>
      </div>

      {error && (
        <div className="mb-4 p-4 rounded-md bg-red-50 text-red-700" role="alert">
          {error}
        </div>
      )}

      {success && (
        <div className="mb-4 p-4 rounded-md bg-green-50 text-green-700" role="alert">
          댓글이 성공적으로 등록되었습니다.
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4 mb-8">
        <div className="flex gap-4 items-start">
          <div className="w-1/4">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="작성자(선택사항)"
              disabled={isSubmitting}
              className="w-full rounded-md border-gray-300 shadow-sm 
                       focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm
                       disabled:bg-gray-100 disabled:cursor-not-allowed"
              aria-label="작성자 이름"
            />
          </div>

          <div className="flex-1">
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="내용을 입력해주세요"
              disabled={isSubmitting}
              className="w-full rounded-md border-gray-300 shadow-sm 
                       focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm
                       disabled:bg-gray-100 disabled:cursor-not-allowed"
              rows="1"
              required
              aria-label="댓글 내용"
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting || !text.trim()}
            className="inline-flex items-center px-5 py-2.5 text-sm font-medium rounded-lg
                     text-white bg-gradient-to-r from-emerald-500 to-green-500
                     hover:from-emerald-600 hover:to-green-600
                     shadow-sm hover:shadow-md border-0
                     transition-all duration-200 ease-in-out
                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500
                     disabled:opacity-50 disabled:cursor-not-allowed
                     disabled:hover:from-emerald-500 disabled:hover:to-green-500"
          >
            {isSubmitting ? '등록 중...' : '등록'}
          </button>
        </div>
      </form>

      {/* 댓글 목록 */}
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-gray-900">댓글 목록</h3>
        {isLoading ? (
          <div className="text-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500 mx-auto"></div>
          </div>
        ) : comments.length > 0 ? (
          comments.map(comment => (
            <CommentItem key={comment.id} comment={comment} />
          ))
        ) : (
          <p className="text-gray-500 text-center py-4">아직 댓글이 없습니다.</p>
        )}
      </div>
    </div>
  );
}

export default Comments;