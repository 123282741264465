import { logEvent } from 'firebase/analytics';
import { analytics, db } from '../services/firebase';
import { doc, runTransaction } from 'firebase/firestore';

/**
 * Firebase Analytics 이벤트 로깅을 위한 유틸리티
 * 프로덕션 환경에서만 실제 이벤트가 전송되며, 개발 환경에서는 콘솔에 출력됩니다.
 */
export const Analytics = {
  /**
   * 기본 이벤트 로깅 메소드
   * @param {string} eventName - 이벤트 이름
   * @param {Object} eventParams - 이벤트 파라미터
   */
  async logEvent(eventName, eventParams = {}) {
    try {
      if (analytics && process.env.NODE_ENV === 'production') {
        // Firebase Analytics 이벤트 로깅
        logEvent(analytics, eventName, {
          ...eventParams,
          timestamp: new Date().toISOString()
        });

        // 인쇄/다운로드 이벤트인 경우에만 stats 업데이트
        if (['report_printed', 'report_downloaded'].includes(eventName)) {
          const statsRef = doc(db, 'stats', 'usage');
          
          await runTransaction(db, async (transaction) => {
            const statsDoc = await transaction.get(statsRef);
            const currentData = statsDoc.data();
            const newCount = (currentData.batchCount || 0) + 1;
            
            if (newCount >= 9) {
              transaction.set(statsRef, {
                total: (currentData.total || 0) + 9,
                batchCount: 0,
                lastUpdated: new Date()
              }, { merge: true });
            } else {
              transaction.set(statsRef, {
                batchCount: newCount
              }, { merge: true });
            }
          });
        }
      } else {
        console.debug('[Analytics Event]:', eventName, eventParams);
      }
    } catch (error) {
      console.warn('[Analytics Error]:', error);
    }
  },

  /**
   * 학교 선택 이벤트
   * @param {string} schoolName - 선택된 학교 이름
   * @param {string} schoolId - 선택된 학교 ID
   */
  schoolSelected(schoolName, schoolId) {
    this.logEvent('school_selected', {
      school_name: schoolName,
      school_id: schoolId
    });
  },

  /**
   * 리포트 인쇄 이벤트
   * @param {string} schoolName - 학교 이름
   */
  reportPrinted(schoolName) {
    this.logEvent('report_printed', {
      school_name: schoolName
    });
  },

  /**
   * 리포트 다운로드 이벤트
   * @param {string} schoolName - 학교 이름
   */
  reportDownloaded(schoolName) {
    this.logEvent('report_downloaded', {
      format: 'pdf',
      school_name: schoolName
    });
  },

  formFieldUsed(fieldName, schoolName) {
    this.logEvent('form_field_used', {
      field_name: fieldName,
      school_name: schoolName
    });
  }
}; 