import React, { useEffect, useState, useMemo } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';

function SchoolSelector({ value, onChange }) {
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        setLoading(true);
        const querySnapshot = await getDocs(collection(db, 'universities'));
        const universitiesData = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            name: doc.data().name,
            hasFields: doc.data().hasFields
          }))
          .filter(university => university.name);

        setUniversities(universitiesData);
      } catch (err) {
        console.error('Error fetching universities:', err);
        setError('학교 목록을 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchUniversities();
  }, []);

  const sortedUniversities = useMemo(() => 
    [...universities].sort((a, b) => 
      a.name.localeCompare(b.name, 'ko-KR')
    ),
    [universities]
  );

  if (loading) return <div>로딩중...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="w-full max-w-2xl">
      <div className="space-y-1 bg-white p-6 rounded-xl shadow-lg border border-gray-100">
        <label htmlFor="university" className="block text-lg font-medium text-gray-900 mb-3">
          학교 <span className="text-red-500">*</span>
        </label>
        <div className="relative">
          <select
            id="university"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="appearance-none block w-full rounded-lg border-2 border-gray-200 bg-white px-4 py-3.5 text-gray-900 placeholder-gray-400 hover:border-indigo-400 focus:border-indigo-500 focus:ring-0 transition-colors sm:text-base"
            style={{
              background: 'none',
              WebkitAppearance: 'none',
              MozAppearance: 'none',
            }}
          >
            <option value="" className="text-gray-500">학교를 선택해주세요</option>
            {sortedUniversities.map((university) => (
              <option 
                key={university.id} 
                value={university.id}
                className="py-2"
              >
                {university.name}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400">
            <svg 
              className="h-5 w-5" 
              viewBox="0 0 20 20" 
              fill="none" 
              stroke="currentColor"
            >
              <path 
                d="M7 7l3 3 3-3" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <p className="text-sm text-gray-500 pl-1 mt-2">
          가나다 순서
        </p>
      </div>
    </div>
  );
}

export default SchoolSelector;