import React, { useState, useEffect } from 'react';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../services/firebase';
import { Analytics } from '../../utils/analytics';

// Noto Sans KR 폰트 등록
Font.register({
  family: 'Noto Sans KR',
  src: 'https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf',
  fontStyle: 'normal',
  fontWeight: 'normal'
});

// PDF 문서 컴포넌트
const ReportDocument = ({ school, formData }) => {
  const [imageData, setImageData] = useState('');

  useEffect(() => {
    const loadImage = async () => {
      try {
        const imageRef = ref(storage, school.templateUrl);
        const downloadUrl = await getDownloadURL(imageRef);
        const response = await fetch(downloadUrl);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => setImageData(reader.result);
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('이미지 로드 실패:', error);
      }
    };
    
    if (school?.templateUrl) {
      loadImage();
    }
  }, [school?.templateUrl]);

  if (!school) return null;

  return (
    <Document>
      <Page size="A4" style={{ padding: 0, margin: 0 }}>
        <View style={{ 
          position: 'relative',
          width: `${school.printWidth}mm`,
          height: `${school.printHeight}mm`
        }}>
          <Image
            src={imageData || school.templateUrl}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
          />
          {school.hasFields && Object.entries(formData).map(([field, value]) => {
            if (value && school[`print_${field}Top`] !== undefined) {
              const displayValue = String(value);
              return (
                <Text
                  key={field}
                  style={{
                    position: 'absolute',
                    top: `${school[`print_${field}Top`]}px`,
                    left: `${school.print_left}px`,
                    fontSize: 18,
                    fontFamily: 'Noto Sans KR'
                  }}
                >
                  {field === 'grade' ? `${displayValue}학년` : displayValue}
                </Text>
              );
            }
            return null;
          })}
        </View>
      </Page>
    </Document>
  );
};

const ActionButtons = ({ school, formData }) => {
  return (
    <div className="flex gap-4">
      <PDFDownloadLink
        document={<ReportDocument school={school} formData={formData} />}
        fileName={`${school.name}_레포트표지.pdf`}
        onClick={() => Analytics.reportDownloaded(school.name)}
      >
        {({ loading }) => (
          <button
            type="button"
            disabled={loading}
            className="inline-flex items-center px-6 py-2.5 text-base font-medium rounded-lg
              text-white bg-gradient-to-r from-indigo-600 to-blue-500 
              hover:from-indigo-700 hover:to-blue-600
              shadow-sm hover:shadow-md border-0
              transition-all duration-200 ease-in-out
              disabled:opacity-50 disabled:cursor-not-allowed
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
            {loading ? '준비중...' : 'PDF 다운로드'}
          </button>
        )}
      </PDFDownloadLink>

      <button
        type="button"
        onClick={async () => {
          try {
            Analytics.reportPrinted(school.name);
            const blob = await pdf(
              <ReportDocument school={school} formData={formData} />
            ).toBlob();
            const url = URL.createObjectURL(blob);
            const printWindow = window.open(url);
            if (printWindow) {
              printWindow.onload = () => {
                printWindow.print();
                URL.revokeObjectURL(url);
              };
            }
          } catch (error) {
            console.error('Print error:', error);
            alert('인쇄 준비 중 오류가 발생했습니다.');
          }
        }}
        className="inline-flex items-center px-6 py-2.5 text-base font-medium rounded-lg
          text-white bg-gradient-to-r from-rose-500 to-pink-500
          hover:from-rose-600 hover:to-pink-600
          shadow-sm hover:shadow-md border-0
          transition-all duration-200 ease-in-out
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
      >
        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
        </svg>
        인쇄하기
      </button>
    </div>
  );
};

export default ActionButtons;